/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import Page from "../layouts/page";
import farm from "../images/farm.jpg"
import firstFrame from "../images/farm_video.png"
import videoMp4 from "../videos/farm.mp4";
import { FaArrowDown } from "react-icons/fa";
import {ReactComponent as Wave} from "../images/wave.svg";
import {ReactComponent as WaveEnd} from "../images/wave_end.svg";

const heroStyles = css`
padding: 40px;
display: flex;

div {
  padding: 20px;
}
`;

const heroFontStyles = css`
font-size: 5em;
`

const imageCss = css`
border-radius: 40px;
`;
  
const videoCss = css`
border-radius: 40px;
width: 100%;
`

const ctaStyles = css`
text-decoration: none;
font-size: 2em;
color: #AFB6E3;
`

const mission = css`
div {
  background-color: #D27979;
  padding-left: 30px;
  color: #ffffff;
}

* {
  margin: 0px;
}
`

const missionDiv = css`
margin-top: 40px;
display: flex;
padding-bottom: 40px;

* {
  align-self: center;
  vertical-align: middle;
}
`

const missionHeader = css`
margin: 0px;
padding: 0px;
font-size: 4em;
`

const missionBlurb = css`
margin-top: 10px;
font-size: 1.2em;
`

const IndexPage = () => {
  return (
    <Page title="Home">
      <section css={heroStyles}>
        <div>
          <h1 css={heroFontStyles}><span style={{color: "#E87979"}}>Bingus</span> is bridging the gap between <span style={{color: "#84A880"}}>Agriculture</span> and <span style={{color: "#BAA4C4"}}>the Future</span></h1>
          <a href="#more" css={ctaStyles}>Learn more about the Bingus mission <FaArrowDown style={{verticalAlign: "middle"}}/></a>
        </div>
        <div>
          <video autoPlay muted loop css={videoCss}>
            <source src={videoMp4} type="video/mp4"/>
            <img css={imageCss} src={firstFrame}/>
          </video>
        </div>
      </section>
      <section css={mission}>
        <Wave style={{transform: "translateY(5px)"}}/>
        <div>
          <h1 css={missionHeader}>The Bingus Mission</h1>
          <div css={missionDiv}>
            <div>
              <img css={imageCss} src={farm}/>
            </div>
            <div>
              <p id="more" css={missionBlurb}>Founded in March of 2022, Bingus LTD has set out to unify the Agricultral world with modern technology. We're in the process of establishing our first products and are excited to have the opportunity to change the agricultural scene for future.</p>
              <p css={missionBlurb}>Our goal is to put revolutionary farming technology in the hands of every farmer across the United Kingdom and eventually the world. We want to allow farmers to utilise the latest agricultural technology to maximise crop yield, animal welfare and overall farm management.</p> 
              <p css={missionBlurb}>The Bingus team is comprised of a diverse mix of experts from a variety of backgrounds including Computer Science, Farming and Land Management.</p>
            </div>
          </div>
        </div>
        <WaveEnd style={{transform: "translateY(-5px)"}}/>
      </section>
    </Page>
  )
}

export default IndexPage;
